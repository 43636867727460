import React, { Component } from 'react';
import Alerta from './Alerta';

export class CuadroAlertas extends Component {

    state={
        sitios:[],
        contador:0
    }



    componentDidUpdate(){
        if(this.state.contador!==this.props.contador){
            this.setState({sitios:this.props.sitios}, ()=> {this.render();});
            this.setState({contador:this.props.contador});            
            
        }
    }
    
    
         
    
    
    render() { 
        var ke=0;     
        return (
            <div style={{backgroundColor:"white"}} id="semaforo" className="container-fluid">   
                <div className="row">
                 {this.state.sitios.map(sitio => <Alerta key={ke++} alerta={sitio}/>)}
              
                </div>
            </div>
        )
    }
}

export default CuadroAlertas
