import React, { Component } from 'react'
/*import iconVerde from './img/gotaverde.png';
import iconRojo from './img/gotaroja.gif';
import iconAmarillo from './img/gotaamarilla.gif';
import iconNegro from './img/gotanegra.png';*/
/*iconos Caudal*/
//import caudalverdep from './img/caudal/caudalverdep.gif';
//import caudalverde from './img/caudal/gotaverdecaudal.png';
import caudalama from './img/caudal/caudalamap75.gif'
//import caudalnaranja from './img/caudal/caudalnaranjap25.gif'
import caudalrojo from './img/caudal/caudalrojop75.gif';
import caudalnegro from './img/caudal/caudalnegrop75.gif';
/*iconos Nivel */
//import nivelverdep from './img/nivel/nivelverde25.gif';
//import nivelverde from './img/nivel/nivelverde.png';
import nivelama from './img/nivel/nivelama75.gif'
//import nivelnaranja from './img/nivel/nivelnaranja25.gif'
import nivelrojo from './img/nivel/nivelrojo75.gif';
import nivelnegro from './img/nivel/nivelnegro75.gif';
/*iconos multiparametros */
//import multiverdep from './img/multiparmetros/multiverde25.gif';
//import multiverde from './img/multiparmetros/multi25.png';
import multiama from './img/multiparmetros/multiamarillo75.gif';
//import multinaranja from './img/multiparmetros/multinaranja25.gif';
import multirojo from './img/multiparmetros/multirojo75.gif';
import multinegro from './img/multiparmetros/multinegro75.gif';
/*iconos PLC*/
//import plcverdep from './img/plc/plcverde25.gif';
//import plcverde from './img/plc/verde25.png';
import plcama from './img/plc/plcamarillo75.gif';
//import plcnaranja from './img/plc/plcnaranja25.gif';
import plcrojo from './img/plc/plcroja75.gif';
import plcnegro from './img/plc/plcnegro75.gif';
export class Alerta extends Component {
    
    
    render() {
        var icono;
        var mensaje ="";
        var estilo="col-12";
      if(this.props.alerta.estatus!=='redint'){
          if(this.props.alerta.tipo===1||this.props.alerta.tipo===2||this.props.alerta.tipo===8){
                estilo="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12"; 
                          
                if(this.props.alerta.estatus==='cero'){
                icono= caudalama;
                mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura cero.';
                }else if(this.props.alerta.estatus==='menor'){
                    icono= caudalama;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura menor al promedio.';
                }
                else if(this.props.alerta.estatus==='mayor'){
                    icono= caudalrojo;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura mayor al promedio.';
                }
                else if(this.props.alerta.estatus==='noconexion'){
                    icono= caudalnegro;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' no tiene conexión con el medidor.';
                }else if(this.props.alerta.estatus==='normal'){
                    return null;
                }
            }
            if(this.props.alerta.tipo===3||this.props.alerta.tipo===4){
                estilo="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12"; 
                          
                if(this.props.alerta.estatus==='cero'){
                icono= nivelama;
                mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura cero.';
                }else if(this.props.alerta.estatus==='menor'){
                    icono= nivelama;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura menor al promedio.';
                }
                else if(this.props.alerta.estatus==='mayor'){
                    icono= nivelrojo;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura mayor al promedio.';
                }
                else if(this.props.alerta.estatus==='noconexion'){
                    icono= nivelnegro;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' no tiene conexión con el medidor.';
                }else if(this.props.alerta.estatus==='normal'){
                    return null;
                }
            }
            if(this.props.alerta.tipo===5||this.props.alerta.tipo===7){
                estilo="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12"; 
                          
                if(this.props.alerta.estatus==='cero'){
                icono= plcama;
                mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura cero.';
                }else if(this.props.alerta.estatus==='menor'){
                    icono= plcama;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura menor al promedio.';
                }
                else if(this.props.alerta.estatus==='mayor'){
                    icono= plcrojo;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura mayor al promedio.';
                }
                else if(this.props.alerta.estatus==='noconexion'){
                    icono= plcnegro;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' no tiene conexión con el medidor.';
                }else if(this.props.alerta.estatus==='normal'){
                    return null;
                }
            }
            if(this.props.alerta.tipo===6){
                estilo="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12"; 
                          
                if(this.props.alerta.estatus==='cero'){
                icono= multiama;
                mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura cero.';
                }else if(this.props.alerta.estatus==='menor'){
                    icono= multiama;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura menor al promedio.';
                }
                else if(this.props.alerta.estatus==='mayor'){
                    icono= multirojo;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' tiene lectura mayor al promedio.';
                }
                else if(this.props.alerta.estatus==='noconexion'){
                    icono= multinegro;
                    mensaje = 'Sitio '+this.props.alerta.nombre+' no tiene conexión con el medidor.';
                }else if(this.props.alerta.estatus==='normal'){
                    return null;
                }
            }
                return (
                
                    <div  key={this.props.alerta.idSitio} className={estilo}>
                        <div className="container-fluid" style={{margin:"0"}}>
                            <div className="row" style={{ borderColor:"white"}}>
                                
                                    <div  className="col-2 text-center"><img src={icono} style={{height:"15px"}} alt="semáforo"/></div>
                                    <div className="col-10 text-center text-primary" style={{color:'#000', fontSize:"14px"}}>{mensaje}</div>
                                
                                
                            </div>
                        </div>
                    </div>
                )
            
        }
         else{
            return null;
        }

       
        
        
    }
}

export default Alerta
